import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import SidebarCategories from "../../../components/sidebar/categories";
import SidebarSearch from "../../../components/sidebar/search";
import SidebarTag from "../../../components/sidebar/sidbar-tag";
import SidebarArchive from "../../../components/sidebar/sidebar-archive";
import SidebarPost from "../../../components/sidebar/sidebar-post";
import SidebarTitle from "../../../components/sidebar/sidebar-title";
import BlogDetailsWrap from "../../../components/blog-details";
import Comment from "../../../components/comment";
import axios from "axios";
import { API_BASE_URL, BASE_URL } from "../../../config/config";
import { useParams } from 'react-router-dom';

const BlogDetailsContainer = () => {

    const { id } = useParams();
    const blog_id = parseInt(id, 10);

    const [blogDetail, setBlogDetail] = useState({})

    const getBlogDetail = async () => {
        try {
            const response = await axios?.post(API_BASE_URL + 'blog/detail', {
                blog_id
            });
            setBlogDetail({ ...response?.data?.Data });
        } catch (error) {
            console.log('error :: ', error);
        }
    };


    const [blogData, setBlogData] = useState([])
    const getBlogData = async () => {
        try {
            const response = await axios.get(API_BASE_URL + 'blog/get')
            console.log('blog data list ::', response?.data)
            if (response?.data?.Status == 200) {
                setBlogData([...response?.data?.Data])
            }
        } catch (error) {
            console.log('errosss :: ', error)
        }
    }
    useEffect(() => {
        getBlogDetail()
        getBlogData()
    }, [id])
    return (
        <div className="blog-section section-py">
            <div className="container">
                <div className="row mb-n7">
                    <div className="col-xl-8 col-lg-8 mb-7">
                        <div className="blog-details-content">
                            <BlogDetailsWrap data={blogDetail} />
                            <div className="blog-comments ">
                                <h3 className="blog-comment-title">
                                    Leave a Reply or Comment
                                </h3>
                                <p>
                                    Top rated construction packages we pleasure
                                    rationally encounter consequences
                                    interesting who loves or pursue or desires
                                    to obtain These cases are perfectly simple
                                    and easy
                                </p>
                            </div>

                            <Comment
                                url=""
                                id={blogDetail?.id}
                                title={blogDetail?.blog_name}
                            />
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 mb-7 offset-xl-1">
                        <div className="widget-wrapper widget-wrapper-nl">
                            {/* <div className="sidebar-widget">
                                <SidebarTitle title="Search" />
                                <SidebarSearch />
                            </div> */}

                            {/* <div className="sidebar-widget">
                                <SidebarTitle title="Categories" />
                                <SidebarCategories data={blogData} />
                            </div> */}
                            <div className="sidebar-widget">
                                <SidebarTitle title="popular post" />
                                <SidebarPost data={blogData} />
                            </div>

                            {/* <div className="sidebar-widget">
                                <SidebarTitle title="Archive" />
                                <SidebarArchive data={blogData} />
                            </div> */}

                            {/* <div className="sidebar-widget">
                                <SidebarTitle title="Tags" />
                                <SidebarTag data={blogData} />
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

// BlogDetailsContainer.propTypes = {
//     data: PropTypes.object,
// };

export default BlogDetailsContainer;
