const BASE_URL =
  window?.location?.hostname == 'franchise.zebsoft.in'
    ? 'https://franchise.zebsoft.in/'
    : `http://localhost:${window?.location?.port}/`;
const SERVER_URL =
  window?.location?.hostname == 'franchise.zebsoft.in'
    ? 'https://franchise.zebsoft.in/admin/'
    : 'https://franchise.zebsoft.in/admin/';

const API_BASE_URL = `${SERVER_URL}api/`;

const DEFAULT_IMAGE = 'uploads/images/default.png';

// apiRoutes Module ------------
const apiRoutes = {
  config: `config`,
  franchise: `franchise`,
};
// -----------

// siteConfig/information ------------
import { storage } from './constant';

const userId = localStorage.getItem(storage?.currentUser)
  ? `Bearer ${JSON.parse(localStorage.getItem(storage?.currentUser)).id}`
  : '0';

/*
const siteConfig = localStorage?.getItem('config')
? JSON.parse(localStorage?.getItem('config'))
: {
    name: 'Franchise - Home',
    shortDescription: 'Franchise maniya',
    logo: '',
    logoSmall: '',
  };*/
const siteConfig = {};

export {
  BASE_URL,
  SERVER_URL,
  API_BASE_URL,
  DEFAULT_IMAGE,
  userId,
  apiRoutes,
  siteConfig,
};
