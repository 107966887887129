import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const Brand = ({ data }) => {
    return (
        <div className="single-brand">
            <Link
                to={`/franchise/${data?.id}/${data?.category_name}`}
            >
                <div title={data?.category_name?.toUpperCase()}>
                    <img src={data?.category_image} alt={data?.category_name} />
                </div>
            </Link>
        </div>
    );
};

Brand.propTypes = {
    data: PropTypes.object,
};

export default Brand;
