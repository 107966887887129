import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { slugify } from "../../utils";
import SidebarTag from "../sidebar/sidbar-tag";
import SocialIcon from "../social-icon";

const BlogDetailsWrap = ({ data }) => {
    const blogDetail = data;

    return (
        <div className="blog-details-wrap">
            <div className="blog-details-thumb">
                <img
                    src={blogDetail?.blog_image}
                    alt={blogDetail?.blog_name}
                    className="w-100"
                />
            </div>
            <p className="blog-details-meta">
                <Link
                    className="author"
                >
                    admin
                </Link>
                <span className="separator">-</span>
                <Link
                    className="date"
                >
                    {blogDetail?.blog_date}
                </Link>
            </p>
            <h3 className="blog-details-title">{blogDetail?.blog_name}</h3>
            <div
                className="desc"
                dangerouslySetInnerHTML={{ __html: blogDetail?.blog_short_description }}
            ></div>
            <div className="social-tags d-sm-flex justify-content-between align-items-center">
                <p className="mb-4 mb-sm-0 d-flex align-items-center">
                    <i className="fa fa-tags"></i>
                    {/* <SidebarTag data={BlogData} /> */}
                </p>

                <ul className="social-links d-flex">
                    <li className="share">
                        <span>Share</span>
                    </li>
                    <li>
                        <SocialIcon
                            classOption="null"
                            path="https://www.facebook.com/"
                            icon="icofont-facebook"
                        />
                    </li>
                    <li>
                        <SocialIcon
                            classOption="null"
                            path="https://www.twitter.com/"
                            icon="icofont-twitter"
                        />
                    </li>
                    <li>
                        <SocialIcon
                            classOption="null"
                            path="https://www.instagram.com/"
                            icon="icofont-instagram"
                        />
                    </li>
                </ul>
            </div>
        </div>
    );
};

BlogDetailsWrap.propTypes = {
    data: PropTypes.object,
};

export default BlogDetailsWrap;