import React, { useEffect, useState } from 'react';
import ScrollToTop from '../components/scroll-to-top';
import SEO from '../components/seo';
import Footer from '../layouts/footer';
import Header from '../layouts/header';
import Layout from '../layouts/index';
import PageBanner from '../containers/global/page-banner';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { apiRoutes } from '../config/config';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';

const Franchise = () => {
  const { categoryId, categoryName } = useParams();
  const [franchiseData, setFranchiseData] = useState([]);

  const getFranchiseData = async () => {
    try {
      const response = await axios?.post(apiRoutes?.franchise + '/list', {
        category_id: categoryId,
      });
      if (response?.data?.Status == 200) {
        setFranchiseData([...response?.data?.Data]);
      }
      console.log('hello : ', response.data);
    } catch (error) {
      console.log('error ::', error);
      setFranchiseData([]);
    }
  };

  useEffect(() => {
    getFranchiseData();
  }, [categoryId]);
  console.log('franchiseData :: ', franchiseData);
  return (
    <React.Fragment>
      <Layout>
        <SEO title="Franchise – Welcome to Apna Franchise" />
        <div className="wrapper">
          <Header />
          <div>
            <PageBanner
              title={categoryName}
              excerpt="Pleasure rationally encounter consequences <br />
                        are extremely painful great oppurtunity"
              image="./../../images/franchise/default.png"
            />
          </div>
          <div className="section-pb">
            <Container>
              <Row>
                <Col xl="12">
                  <h5>{categoryName}</h5>
                </Col>
                <Col xl="12">
                  <Row>
                    {franchiseData?.map((row, index) => (
                      <Col xl="3" lg="4" md="6" xs="12" key={index}>
                        <div className="h-100 franchiseBox">
                          <div className="img text-center">
                            <img
                              src={row?.franchise_image}
                              alt={row?.franchise_name}
                            />
                          </div>
                          <div className="info">
                            <h2>{row?.franchise_name}</h2>
                            <div className="d-flex w-100 justify-content-between align-items-center">
                              <div className="price">
                                {row?.franchise_price}
                              </div>
                              <Button>Know More</Button>
                            </div>
                          </div>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
            </Container>
          </div>
          <Footer />
          <ScrollToTop />
        </div>
      </Layout>
    </React.Fragment>
  );
};

export default Franchise;
