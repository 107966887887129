import React, { useEffect, useState } from 'react';
import ScrollToTop from '../components/scroll-to-top';
import SEO from '../components/seo';
import Footer from '../layouts/footer';
import Header from '../layouts/header';
import Layout from '../layouts/index';
import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Input,
  Label,
  NavLink,
  Row,
} from 'reactstrap';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { API_BASE_URL, BASE_URL } from '../config/config';
import { compose } from 'redux';
import { toast } from 'react-toastify';
import { storage } from '../config/constant';

const Login = () => {
  const [loginForm, setLoginForm] = useState({});
  const [btnDisabled, setBtnDisabled] = useState(false);
  const { handleSubmit } = useForm();

  const formSubmit = async () => {
    setBtnDisabled(true);
    try {
      const response = await axios?.post(API_BASE_URL + 'login', loginForm);
      console.log('response :: ', response);
      if (response?.data?.Status == 200) {
        toast('Welcome ' + response?.data?.Data?.user_name);
        localStorage.setItem(
          storage?.currentUser,
          JSON.stringify({ ...response?.data?.Data })
        );

        setTimeout(() => {
          window.location = BASE_URL;
        }, 2000);
      } else {
        setBtnDisabled(false);
      }
    } catch (error) {
      console.log('error :: ', error);
      setBtnDisabled(false);
    }
  };

  useEffect(() => {
    const user = JSON.parse(
      localStorage?.getItem(storage?.currentUser)
        ? localStorage?.getItem(storage?.currentUser)
        : '{}'
    );
    if (user?.id > 0) {
      alert('You are already logged in...');
      window.location = BASE_URL;
    }
  }, []);

  return (
    <React.Fragment>
      <Layout>
        <SEO title="Franchise – Contact" />
        <div className="wrapper">
          <Header />
          <section className="contact-section section-py pb-0">
            <Container>
              <Row className="justify-content-center">
                <Col xl="6" lg="7" md="8" sm="10" xs="12">
                  <div
                    className={`feature-section position-relative section-pb`}
                  >
                    <img
                      className="path-img"
                      src={`${process.env.PUBLIC_URL}/images/feature/shape.png`}
                      alt="images_not_found"
                    />
                    <div className="container custom-container">
                      <div className="row g-0 align-items-center my-5">
                        <div>
                          <Card className="feature-card bg-light border-0">
                            <Form
                              method="post"
                              onSubmit={handleSubmit(formSubmit)}
                            >
                              <div className="title-section text-center">
                                <span className="sub-title">LOGIN</span>
                                <h4
                                  className=""
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      "<b>Sign In to <span class='text-primary'>Your Account</span></b>",
                                  }}
                                ></h4>
                                <p
                                  className="mb-2 px-5"
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      'Please enter your credentials to access your account.',
                                  }}
                                ></p>
                              </div>
                              <div className="form-group px-3 text-capitalize mb-3">
                                <Label className="form-label mb-0">
                                  <b>username</b>
                                </Label>
                                <Input
                                  className="bg-transparent text-primary font-weight-bold border-2"
                                  placeholder="enter email address"
                                  value={loginForm?.username}
                                  onChange={e => {
                                    loginForm.username = e?.target?.value;
                                    setLoginForm({ ...loginForm });
                                  }}
                                />
                              </div>
                              <div className="form-group px-3 text-capitalize">
                                <Label className="form-label mb-0">
                                  <b>password</b>
                                </Label>
                                <Input
                                  className="bg-transparent text-primary font-weight-bold border-2"
                                  type="password"
                                  placeholder="enter password"
                                  value={loginForm?.password}
                                  onChange={e => {
                                    loginForm.password = e?.target?.value;
                                    setLoginForm({ ...loginForm });
                                  }}
                                />
                              </div>
                              <div className="px-4 py-3 text-center">
                                <Button
                                  color="primary"
                                  disabled={btnDisabled}
                                  className="rounded-3 display-4"
                                >
                                  Log in
                                </Button>
                                <br />
                                <Link to="/register">
                                  <b>I don&apos;t have a account</b>
                                </Link>
                              </div>
                            </Form>
                          </Card>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <Footer />
          <ScrollToTop />
        </div>
      </Layout>
    </React.Fragment>
  );
};

export default Login;
