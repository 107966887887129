import React, { useEffect, useState } from 'react';
import BlogItemContainer from '../containers/blog/blog-item';
import NewsletterArea from '../containers/global/newsletter';
import PageBanner from '../containers/global/page-banner';
import Footer from '../layouts/footer';
import Header from '../layouts/header';
import Layout from '../layouts/index';
import ScrollToTop from '../components/scroll-to-top';
import SEO from '../components/seo';
import { API_BASE_URL } from '../config/config';
import axios from 'axios';

const BlogPage = () => {
  const [blogData, setBlogData] = useState([]);

  const getBlog = async () => {
    try {
      const response = await axios?.get(API_BASE_URL + 'blog/get');
      console.log('response :: ', response);
      setBlogData([...response?.data?.Data]);
    } catch (error) {
      console.log('error :: ', error);
    }
  };
  useEffect(() => {
    getBlog();
  }, []);

  return (
    <React.Fragment>
      <Layout>
        <SEO title="Franchise – Blog" />
        <div className="wrapper">
          <Header />
          <PageBanner
            title="Blog Post"
            excerpt="Pleasure rationally encounter consequences <br />
                        are extremely painful great oppurtunity"
            image="./images/blog/banner.png"
          />
          <div className="mt-5">
            <BlogItemContainer data={blogData} />
          </div>
          <NewsletterArea />
          <Footer />
          <ScrollToTop />
        </div>
      </Layout>
    </React.Fragment>
  );
};

export default BlogPage;
