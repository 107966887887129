import React from 'react';
import PropTypes from 'prop-types';
import NewsletterArea from '../containers/global/newsletter';
import PageBanner from '../containers/global/page-banner';
import Footer from '../layouts/footer';
import Header from '../layouts/header';
import Layout from '../layouts/index';
import BlogDetailsContainer from '../containers/blog/blog-details';
import BlogData from '../data/blog.json';
import ScrollToTop from '../components/scroll-to-top';
import SEO from '../components/seo';

import { useParams } from 'react-router-dom';

const BlogDetailsPage = () => {
  const { id } = useParams();

  const blogId = parseInt(id, 10);

  return (
    <React.Fragment>
      <Layout>
        <SEO title="Franchise – Blog Details" />
        <div className="wrapper">
          <Header />
          <PageBanner
            title="Blog Details"
            excerpt="Pleasure rationally encounter consequences <br />
                        are extremely painful great oppurtunity"
            image="/images/blog/banner.png"
          />
          <BlogDetailsContainer blogId={blogId} />
          <NewsletterArea />
          <Footer />
          <ScrollToTop />
        </div>
      </Layout>
    </React.Fragment>
  );
};

// BlogDetailsPage.propTypes = {
//   match: PropTypes.shape({
//     params: PropTypes.shape({
//       id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
//     }),
//   }),
// };

export default BlogDetailsPage;
