import { createSlice } from '@reduxjs/toolkit';
import { getConfig } from './configThunks';
import { storage } from '../../config/constant';

const configSlice = createSlice({
  name: 'config',
  initialState: {
    siteConfig: localStorage?.getItem(storage?.config)
      ? JSON.parse(localStorage?.getItem(storage?.config))
      : {},
    data: null,
    status: 'idle',
    error: null,
  },
  extraReducers: builder => {
    builder
      // get Config section ------------
      .addCase(getConfig.pending, state => {
        state.status = 'loading';
      })
      .addCase(getConfig.fulfilled, (state, action) => {
        state.siteConfig = action.payload?.Data;
        localStorage.setItem(
          storage.config,
          JSON.stringify(action.payload?.Data)
        );
        state.status = 'succeeded';
      })
      .addCase(getConfig.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
    // ------------
  },
});

export default configSlice.reducer;
