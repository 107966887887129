import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux';
import rootReducer from './redux/store';
import { configureStore } from '@reduxjs/toolkit';
import { API_BASE_URL } from './config/config';
import { storage } from './config/constant';
import axios from 'axios';

const store = configureStore({ reducer: rootReducer, devTools: true });
const container = document.getElementById('root');
const root = createRoot(container);

// default
axios.defaults.baseURL = API_BASE_URL;
// content type
axios.defaults.headers.post['Content-Type'] = 'application/json';

axios.defaults.maxBodyLength = 'Infinity';

// content type
const userId = JSON.parse(localStorage.getItem(store?.currentUser))
  ? JSON.parse(localStorage.getItem(storage?.currentUser)).id
  : 0;
axios.defaults.headers.common['user_id'] = userId > 0 ? userId : '0';

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
