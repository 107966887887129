import React from "react";

import ContactForm from "../../components/contact-form";
import ContactInfo from "../../components/contact-info";
import ContactData from "../../data/contact.json";

import { useSelector } from 'react-redux';

const ContactContainer = () => {
    const siteConfig = useSelector(state => state.Config?.siteConfig);
    return (
        <section className="contact-section section-py">
            <div className="container">
                <div className="row mb-n7">
                    {/* <div className="col-xl-6 col-lg-6 mb-7">
                        <div className="contact-title-section">
                            <h3 className="title">Get in touch</h3>
                            <p>
                                Top rated construction packages we pleasure
                                rationally encounter
                                <br className="d-none d-xl-block" />
                                consequences interesting who loves or pursue or
                                desires
                            </p>
                        </div>

                        <ContactForm />
                    </div> */}

                    <div className="col-xl-4 col-md-6 col-xs-12">
                        <div className="h-100 contact-address text-center">
                            <div className="address-list mb-4">
                                <h4 className="title">Office Address</h4>
                                <p className="mb-0">{siteConfig?.address}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-6 col-xs-12">
                        <div className="h-100 contact-address text-center">
                            <div className="address-list mb-4">
                                <h4 className="title">Phone Number</h4>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={`tel:${siteConfig?.contact}`}
                                >
                                    {siteConfig?.contact}
                                </a>
                                <br />
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={`https://api.whatsapp.com/send/?phone=91${siteConfig?.whatsappNumber}&text=Hi&type=phone_number&app_absent=0`}
                                >
                                    {siteConfig?.whatsappNumber}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-6 col-xs-12">
                        <div className="h-100 contact-address text-center">
                            <div className="address-list mb-1 pb-2">
                                <h4 className="title">Web Address</h4>
                                <p className="mb-0">{siteConfig?.email}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ContactContainer;
