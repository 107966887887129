import React, { useEffect, useState } from 'react';
import ScrollToTop from '../components/scroll-to-top';
import SEO from '../components/seo';
import BrandContainer from '../containers/global/brand/index';
import FunFactContainer from '../containers/global/funfact';
import IconBoxContainer from '../containers/global/icon-box';
import NewsletterArea from '../containers/global/newsletter';
import TeamContainer from '../containers/global/team';
import TestimonialContainer from '../containers/global/testimonial';
import HomeAboutContainer from '../containers/home/about';
import HomeBlog from '../containers/home/blog';
import IntroContainer from '../containers/home/intro';
import ServiceListContainer from '../containers/service/service-list';
import Footer from '../layouts/footer';
import Header from '../layouts/header';
import Layout from '../layouts/index';
import axios from 'axios';
import { API_BASE_URL } from '../config/config';

const HomePage = () => {
  const [homeData, setHomeData] = useState({});

  const getHomeData = async () => {
    try {
      const response = await axios.get(API_BASE_URL + 'home');

      if (response?.data?.Status == 200) {
        setHomeData({ ...response?.data?.Data });
      }
    } catch (error) {
      console.log('error home :: ', error);
    }
  };

  useEffect(() => {
    getHomeData();
  }, []);

  return (
    <React.Fragment>
      <Layout>
        <SEO title="Franchise – Welcome to Apna Franchise" />
        <div className="wrapper">
          <Header />
          <IntroContainer />
          <BrandContainer data={homeData?.category} />
          <IconBoxContainer classOption="section-pb" />
          <HomeAboutContainer />
          <ServiceListContainer />
          <TestimonialContainer />
          <FunFactContainer classOption="mt-10 mt-lg-0" />
          <TeamContainer classOption="section-pb" />
          {/* <HomeBlog /> */}
          {/* <NewsletterArea /> */}
          <Footer />
          <ScrollToTop />
        </div>
      </Layout>
    </React.Fragment>
  );
};

export default HomePage;
