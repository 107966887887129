import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { apiRoutes } from '../../config/config';

// import toastComponent from '../../Components/toaster/Toaster';

// config listing/get section ------------
export const getConfig = createAsyncThunk('config', async obj => {
  try {
    const { data, status } = await axios?.get(apiRoutes?.config);
    return { status, ...data };
  } catch (error) {
    return { status: error?.response?.status, ...error?.response?.data };
  }
});
// ------------
