import React from 'react';
import ScrollToTop from '../components/scroll-to-top';
import SEO from '../components/seo';
import AccordionContainer from '../containers/accordion';
import NewsletterArea from '../containers/global/newsletter';
import PageBanner from '../containers/global/page-banner';
import Footer from '../layouts/footer';
import Header from '../layouts/header';
import Layout from '../layouts/index';

const FaqPage = () => {
  return (
    <React.Fragment>
      <Layout>
        <SEO title="Franchise – Faq" />
        <div className="wrapper">
          <Header />
          <PageBanner
            title="FAQ"
            excerpt="Pleasure rationally encounter consequences <br />
                        are extremely painful great oppurtunity"
            image="./images/faq/1.png"
          />
          <AccordionContainer />
          <NewsletterArea />
          <Footer />
          <ScrollToTop />
        </div>
      </Layout>
    </React.Fragment>
  );
};

export default FaqPage;
