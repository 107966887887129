import { NavLink } from "react-router-dom";
import { Button } from "reactstrap";
import { BASE_URL } from "../../../config/config";
import { storage } from "../../../config/constant";

const MainMenu = () => {

    const loginDetail = localStorage?.getItem(storage?.currentUser) ? JSON.parse(localStorage?.getItem(storage?.currentUser)) : {};


    return (
        <nav className="main-menu d-none d-lg-block">
            <ul className="d-flex align-items-center">
                <li>
                    <NavLink exact to="/">
                        Home
                    </NavLink>
                </li>
                <li>
                    <NavLink to='blog'>Blog</NavLink>
                </li>
                <li>
                    <NavLink to='about'>Aboutus</NavLink>
                </li>
                <li>
                    <NavLink to='contact'>Contactus</NavLink>
                </li>
                {
                    loginDetail?.id ? (
                        <li>
                            <NavLink to='/login'>
                                <Button
                                    color="primary" className="rounded-5">Welcome {loginDetail?.user_name}</Button>
                            </NavLink>
                            <ul className="sub-menu pb-1">
                                <li className="sub-menu-item">
                                    <NavLink
                                        className="sub-menu-link"
                                        to='/my-franchises'
                                    >
                                        My Franchises
                                    </NavLink>
                                </li>
                                <li className="sub-menu-item">
                                    <NavLink
                                        className="sub-menu-link"
                                        to='/profile'
                                    >
                                        Profile
                                    </NavLink>
                                </li>
                                <li className="sub-menu-item">
                                    <NavLink
                                        className="sub-menu-link"
                                        to={'#'}
                                        onClick={() => {
                                            localStorage?.removeItem(storage?.currentUser)
                                            window.location.reload();
                                        }}
                                    >
                                        Logout
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                    ) : (
                        <li>
                            <NavLink to='login'>
                                <Button
                                    color="primary" className="rounded-5">Login</Button>
                            </NavLink>
                        </li>
                    )
                }
            </ul>
        </nav>
    );
};

export default MainMenu;
